import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box } from "@mui/material";

const WhatsAppComponent = () => {
  const handleClick = () => {
    const url = `https://wa.me/${917385411057}?text=${encodeURIComponent(
      "I want more information about Urbanbrick Vista NA Plots"
    )}`;
    window.open(url, "_blank");
  };

  return (
    <Box
      sx={{
        color: "green",
        borderRadius: "20px",
        textAlign: "center",
        cursor: "pointer",
      }}
    >
      <WhatsAppIcon
        onClick={handleClick}
        sx={{ fontSize: { xs: "40px", sm: "70px", md: "50px" } }}
      />
    </Box>
  );
};

export default WhatsAppComponent;
