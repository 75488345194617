import React from "react";
import "./Infrastructure.css";
import { Box, Container, Grid, Typography } from "@mui/material";
import gate from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/gate.png";
import road from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/road.png";
import water from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/water-tap.png";
import energy from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/energy.png";
import wall from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/wall.png";
import roadside from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/roadside.png";
import security from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/security.png";
import light from "../UrbanBrick Website Asset File/Fully Developed Infrastructure Icon/street-light.png";
import leaf from "../UrbanBrick Website Asset File/Leaf image/Leaf Image 03-01.png"

const Infrastructure = () => {
  return (
    <Box className="blue-container" py={20} id='infrastructure'>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' },
            textAlign: 'center',
            mb: 4,
            position: 'relative',
            zIndex: 2
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Kaisei Tokumin",
              fontStyle: "normal",
              lineHeight: "51px",
              wordWrap: "break-word",
              zIndex: 2
            }}
          >
            <span
                  style={{
                    position: "absolute",
                    width: "57px",
                    height: "57px",
                    left: '9%',
                    zIndex: -1,
                    background: "#F7931E",
                    borderRadius: '50%'
                  }}
                ></span>
            Fully Developed <br /> Infrastructure
          </Typography>
        </Box>
      <Typography className="infrastructure-text" pb={10}>
        Urbanbrick Vista boasts a state-of-the-art infrastructure, ensuring a
        seamless blend of modern amenities and natural beauty.
      </Typography>
      <Container>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={gate} alt="" width={"50%"} />
              <Typography className="infra-text">Gated Community</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={road} alt="" width={"50%"} />
              <Typography className="infra-text">
                Internal roads in concrete & paver blocks
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={water} alt="" width={"50%"} />
              <Typography className="infra-text">
                Uninterrupted water suppy
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={energy} alt="" width={"50%"} />
              <Typography className="infra-text">
                Provision for electricity
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={4} sx={{display: { xs: 'none', sm: 'flex' },}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                width: "100%",
                height: "216px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                  fontFamily: "Kaisei Tokumin",
                  fontStyle: "normal",
                  lineHeight: "51px",
                  wordWrap: "break-word",
                  position: "absolute",
                  zIndex: 1,
                }}
              >
                <span
                  className="ellipse"
                  style={{
                    position: "absolute",
                    width: "57px",
                    height: "57px",
                    left: "0%",
                    top: "0%",
                    zIndex: -1,
                    background: "#F7931E",
                  }}
                ></span>
                Fully Developed <br /> Infrastructure
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={wall} alt="" width={"50%"} />
              <Typography className="infra-text">
                Compound wall for individual plots
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={roadside} alt="" width={"50%"} />
              <Typography className="infra-text">
                Roadside landscaping
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={security} alt="" width={"50%"} />
              <Typography className="infra-text">24x7 security</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box className="infra">
              <img src={light} alt="" width={"50%"} />
              <Typography className="infra-text">Street lights</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box className='leaf-3'>
        <img src={leaf} alt="" width={'100%'} />
      </Box>
    </Box>
  );
};

export default Infrastructure;
