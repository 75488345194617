import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Logo from "../UrbanBrick Website Asset File/Image/Logo.png";
import Leaf from "../UrbanBrick Website Asset File/Leaf image/Leaf.png";
import Image from "../UrbanBrick Website Asset File/Image/hero image 2.png";
import './HeroSection.css'
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 750,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobileSmall: 320,
        mobileMedium: 375,
        mobileLarge: 425,
      },
    },
  });

const HeroSection = () => {
  return (
    <Box>
      <Box className="hero-section">
        <Box className="logo">
          <img src={Logo} alt="" width={"100%"} />
        </Box>
        <Typography variant="h4" className="hero-title">
          Bungalow Lifestyle with
        </Typography>
        <Typography variant="h1" className="hero-text">
          Front Row Seats
        </Typography>

        <Box className="hero-mobile">
          <Typography
            variant="h5"
            className="address-text"
            sx={{ marginTop: { xs: "-10px", sm: "40px" } }}
          >
            Gated Community{" "}
            <span style={{ fontWeight: 600}}>NA PLOTS AND SERENE VILLAS</span>{" "}
            <br />
            <Typography fontSize={'22px'} mt={1}>Near Ghotawade Phata, Mulshi.</Typography>

          </Typography>
          <Typography
            variant="h5"
            className="price-text"
            sx={{ marginTop: { xs: "10px", sm: "40px" } }}
          >
            Starting From{" "}
            <span style={{ fontSize: "28px", fontWeight: 500 }}>₹45 Lakh</span>{" "}
            (AI)
          </Typography>
          <Box
            className="heading"
            id='overview'
            sx={{ marginTop: { xs: "300px", sm: "70%", md: "50%" } }}
          >
            <Typography variant="h4">
              <span className="ellipse"></span>
              Overview
            </Typography>
            <Box
              className="overview-text"
              sx={{ paddingTop: { xs: "20%", sm: "10%" } }}
            >
              <Typography>
                Urbanbrick Vista, located just 10min from Hinjawadi, the booming
                IT hub of Pune, offers a unique investment opportunity in premium
                residential NA plots & villas. Covering 40 acres, the project
                features plot sizes from 1,500 to 5,000 sq. ft., all with clear
                titles and individual 7/12 extracts. Approved by the collector,
                PMRDA sanctioned and RERA registered, the project ensures
                investment security.
              </Typography>
              <Typography>
                Enjoy the best of both worlds with excellent connectivity to Pune
                and Mumbai, proximity to top educational institutions, and
                breathtaking natural attractions like Tamhini Ghat and Mulshi Dam.
              </Typography>
              <Typography>
                With premium infrastructure and 20+ modern amenities, Urbanbrick
                Vista promises a lifestyle of comfort and convenience.
              </Typography>
            </Box>
            <Box className="leaf-1">
              <img src={Leaf} alt="" width={"100%"} />
            </Box>
          </Box>
        </Box>

        <ThemeProvider theme={theme}>
          <Container
            className="highlight"
            id='highlights'
            sx={{ marginTop: { mobileSmall: '250%', mobileMedium: '160%', mobileLarge: '130%', xs: "200%", sm: "50%", md: "25%" } }}
          >
            <Grid container columnSpacing={{ sm: 5, md: 20 }} rowSpacing={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h4">
                  <span className="ellipse-2"></span>
                  Highlights
                </Typography>
                <Box
                  className="highlight-text"
                  sx={{ paddingTop: { xs: "20%", sm: "20%" } }}
                >
                  <Typography>
                    With its strategic location, proximity to the 110m Ring Road,
                    comprehensive infrastructure, and competitive pricing,
                    Urbanbrick Vista presents a unique opportunity for both
                    investors and homebuyers.
                  </Typography>
                  <Typography sx={{ marginTop: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                      Plot Details:
                    </span>{" "}
                    <br />
                    Plot Sizes: Ranging from 1,500 sq. ft. to 5,000 sq. ft. <br />
                    Individual 7/12 Extract: Ensuring clear title and ownership.{" "}
                    <br />
                    Ready-to-Build: Fully developed plots with all necessary
                    infrastructure in place.
                  </Typography>
                  <Typography sx={{ marginTop: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                      Pricing:
                    </span>{" "}
                    <br />
                    Starting Price: From 45 lakh to 1.40 crore, all inclusive.{" "}
                    <br />
                    Flexible Payment Options: Bank loan availability to ease the
                    investment process.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={6}>
                    <Box className="highlight-1">
                      <Typography variant="h2">40</Typography>
                      <Typography>Acres land</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="highlight-2">
                      <Typography variant="h2">7/12</Typography>
                      <Typography>Individual</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="highlight-2">
                      <Typography variant="h2">20+</Typography>
                      <Typography>Amenities</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="highlight-1">
                      <Typography variant="h2">
                        1500 <span style={{ fontSize: "16px" }}>to</span> 5000{" "}
                        <span style={{ fontSize: "16px" }}>sq.ft</span>
                      </Typography>
                      <Typography>Plot Sizes</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <img src={Image} alt="" width="100%" />
        </Box>
    </Box>
  );
};

export default HeroSection;
