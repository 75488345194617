import { Box, CssBaseline } from "@mui/material";
import "./App.css";
import HeroSection from "./components/HeroSection";
import Amenities from "./components/Amenities";
import Infrastructure from "./components/Infrastructure";
import Gallery from "./components/Gallery";
import MasterLayout from "./components/MasterLayout";
import Location from "./components/Location";
import Contact from "./components/Contact";
import { NavigationBar } from "./components/NavigationBar";
import EnquiryModal from "./components/EnquiryModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsAppComponent from "./components/WhatsappComponent";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-W4TMH2LB'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ToastContainer />
      <NavigationBar />
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          position: "fixed",
          right: "-1%",
          top: "50%",
          zIndex: 100,
        }}
      >
        <EnquiryModal />
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          position: "fixed",
          right: "0%",
          bottom: "0%",
          zIndex: 100,
          width: "100%",
        }}
      >
        <EnquiryModal />
      </Box>
      <Box sx={{position: 'fixed', bottom: '10%', right: '1%', zIndex: 100}}>
        <WhatsAppComponent />
      </Box>
      <HeroSection />
      <Amenities />
      <Infrastructure />
      <Gallery />
      <MasterLayout />
      <Location />
      <Contact />
    </div>
  );
}

export default App;
