import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link as ScrollLink } from "react-scroll";

const drawerWidth = 240;
const navItems = [
  { label: "Overview", id: "overview" },
  { label: "Highlights", id: "highlights" },
  { label: "Amenities", id: "amenities" },
  // { label: "Infrastructure", id: "infrastructure" },
  { label: "Gallery", id: "gallery" },
  { label: "Location", id: "location" },
  { label: "Contact Us", id: "contact" },
];

export const NavigationBar = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        <ScrollLink
          to={"overview"}
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
          onClick={handleDrawerToggle}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Overview"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"highlights"}
          smooth={true}
          duration={500}
          spy={true}
          offset={30}
          onClick={handleDrawerToggle}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Highlights"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"amenities"}
          smooth={true}
          duration={500}
          spy={true}
          offset={-70}
          onClick={handleDrawerToggle}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Amenities"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"infrastructure"}
          smooth={true}
          duration={500}
          spy={true}
          offset={50}
          onClick={handleDrawerToggle}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Infrastructure"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"gallery"}
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
          onClick={handleDrawerToggle}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Gallery"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"layout"}
          smooth={true}
          duration={500}
          spy={true}
          offset={-70}
          onClick={handleDrawerToggle}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Layout"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"location"}
          smooth={true}
          duration={500}
          spy={true}
          offset={-70}
          onClick={handleDrawerToggle}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Location"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
        <ScrollLink
          to={"contact"}
          smooth={true}
          duration={500}
          spy={true}
          offset={-70}
          onClick={handleDrawerToggle}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={"Contact Us"} className="mobile-nav"/>
            </ListItemButton>
          </ListItem>
        </ScrollLink>
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar
        component="nav"
        sx={{
          background: isScrolled ? "#fff" : "transparent",
            boxShadow: isScrolled ? "0 2px 4px #f7931e" : "none",
            position: isScrolled ? "fixed" : "relative",
            transition: "background 0.3s, box-shadow 0.3s",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              width: "100%",
              justifyContent: "end",
            }}
          >

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,

                color: "#000",

                background: "none",
                "&:hover": {
                  background: "none",
                },
              }}
            >
              <MenuIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "flex" }, justifyContent: 'end', width: '100%' }}>
            {navItems.map((item) => (
              <ScrollLink
                key={item.id}
                to={item.id}
                smooth={true}
                duration={500}
                spy={true}
                offset={-100}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button
                  sx={{
                    color: "#000",
                    fontFamily: "Kaisei Tokumin",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "29px",
                    marginRight: "30px",
                    "&:hover": {
                      color: "orange",
                      fontWeight: "bold",
                      background: "none",
                    },
                  }}
                >
                  {item.label}
                </Button>
              </ScrollLink>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};
