import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../UrbanBrick Website Asset File/Image/WhatsApp Image 2024-07-20 at 14.56.53_49911f55 1.png";
import image2 from "../UrbanBrick Website Asset File/Gallery Images (webp)/Bungalow Lifestyle (2).webp";
import image3 from "../UrbanBrick Website Asset File/Gallery Images (webp)/full-shot-woman-running-outdoors.webp";
import image4 from "../UrbanBrick Website Asset File/Gallery Images (webp)/image 1.webp";
import './Gallery.css'

const Gallery = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    lazyLoad: true
  };

  return (
    <Box sx={{overflow: 'hidden'}}>
      <Box
        className="heading"
        id='gallery'
        sx={{
          marginTop: { xs: "50%", sm: "10%", md: "10%" },
          marginBottom: "100px",
        }}
      >
        <Typography variant="h4">
          <span className="ellipse"></span>
          Gallery
        </Typography>
      </Box>
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src={image1} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image2} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image3} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image4} alt="" className="slider-image" />
          </div>
        </Slider>
      </div>
    </Box>
  );
};

export default Gallery;
