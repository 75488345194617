import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import "./Location.css";
import logo from "../UrbanBrick Website Asset File/Image/Logo.png";
import roadCircle from "../UrbanBrick Website Asset File/Location icon/circle-of-road.png";
import car from "../UrbanBrick Website Asset File/Location icon/car.png";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setSubmitted } from "../redux/leadGenerationSlice";
import { useSearchParams } from "react-router-dom";

const style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const messageStyle = {
  ...style,
  width: 300,
  background: "#fff",
  padding: "20px",
  borderRadius: "10px",
};

const Location = () => {
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const dispatch = useDispatch();
  const isSubmitted = useSelector((state) => state.submitted.isSubmitted);
  const [searchParams] = useSearchParams();
  const utmParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorClose = () => setErrorOpen(false);

  const validateName = (name) => {
    if (!name) {
      setNameError("Name is required.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required.");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validateNumber = (number) => {
    if (!number) {
      setNumberError("Number is required.");
      return false;
    } else if (!/^[6-9][0-9]{9}$/.test(number)) {
      setNumberError("Invalid Mobile Number.");
      return false;
    } else {
      setNumberError("");
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isNameValid = validateName(name);
    const isNumberValid = validateNumber(number);
    const isEmailValid = validateEmail(email);

    if (!isNameValid || !isNumberValid || !isEmailValid) return;

    const TELEGRAM_BOT_TOKEN = "7030443585:AAHLfBsrTuQAPhrgsTFSACEMs8gwdZ3oA7g";
    const TELEGRAM_CHAT_ID = -4139008254;
    const text = `New lead received for UrbanBrick Vista:\n\nName: ${name}\nEmail: ${email}\nNumber: ${number}\n\nutm: ${JSON.stringify(utmParams)}\n\nLead from: Google Map`;
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: TELEGRAM_CHAT_ID,
        text: text,
      });
      setOpen(false);
      setSuccessOpen(true);
      dispatch(setSubmitted());
    } catch (error) {
      setErrorMessage(error);
      setErrorOpen(true);
    }
  };

  return (
    <div>
      <Box
        className="heading"
        id="location"
        sx={{ marginTop: { xs: "30%", sm: "10%", md: "10%" } }}
      >
        <Typography variant="h4">
          <span className="ellipse"></span>
          Location
        </Typography>
        <Box
          className=""
          sx={{
            paddingTop: { xs: "5%", sm: "8%" },
            margin: "auto",
            width: "89%",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "200%",
            textAlign: "center",
          }}
        >
          <Typography mt={{ xs: 3, md: 0 }}>
            Located in the serene Mulshi Taluka, Urbanbrick Vista offers a
            picturesque setting in Pirangut, near Ghotawade Phata. This prime
            location offers a tranquil escape from the hustle and bustle of city
            life while maintaining excellent connectivity to major urban
            centers. Residents can enjoy the scenic beauty while being close to
            major urban centers. It's an ideal choice for those seeking both
            convenience and natural beauty.
          </Typography>
        </Box>
        <Grid container rowSpacing={5} mt={5}>
          <Grid item xs={12} md={6}>
            <Container
              sx={{
                width: "85%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                }}
              >
                Hyperconnectivity
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                  fontSize: "14px",
                }}
              >
                📌 Just 1 minute from the proposed Pune 110 Meter Ring Road
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                  fontSize: "14px",
                }}
              >
                📌 Easy access to Pune and Mumbai
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container
              sx={{
                textAlign: { xs: "start", md: "end" },
                width: "85%",
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "25px",
                lineHeight: "200%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                }}
              >
                Educational Hubs
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                  fontSize: "14px",
                }}
              >
                📌 Close to several reputed colleges and educational institutes
              </Typography>
            </Container>
          </Grid>
        </Grid>

        <Box
          sx={{ position: "relative", display: { xs: "none", sm: "block" } }}
        >
          <Box className="circle-1"></Box>
          <Box className="circle-2">
            <Box className="location-3">
              <Box className="inner-circle-1">
                <img src={car} alt="" width={"80%"} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                    fontFamily: "Inter",
                  }}
                >
                  Just 20 mins
                </Typography>
                <Typography sx={{ fontSize: "12px", fontFamily: "Inter" }}>
                  Drive from Chandni chowk
                </Typography>
              </Box>
            </Box>

            <Box className="location-4">
              <Box className="inner-circle-1">
                <img src={car} alt="" width={"80%"} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                    fontFamily: "Inter",
                  }}
                >
                  Just 20 mins
                </Typography>
                <Typography sx={{ fontSize: "12px", fontFamily: "Inter" }}>
                  Drive from Balewadi
                </Typography>
              </Box>
            </Box>

            <Box className="location-5">
              <Box className="inner-circle-1">
                <img src={car} alt="" width={"80%"} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                    fontFamily: "Inter",
                  }}
                >
                  Just 20 mins
                </Typography>
                <Typography sx={{ fontSize: "12px", fontFamily: "Inter" }}>
                  Drive from Sus
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="circle-3">
            <Box className="location-2">
              <Box className="inner-circle-1">
                <img src={car} alt="" width={"80%"} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                    fontFamily: "Inter",
                  }}
                >
                  Just 10 mins
                </Typography>
                <Typography sx={{ fontSize: "12px", fontFamily: "Inter" }}>
                  Drive from Hinjewadi Phase 3
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="circle-4">
            <Box className="location-1">
              <Box className="inner-circle-1">
                <img src={roadCircle} alt="" width={"80%"} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                    fontFamily: "Inter",
                  }}
                >
                  Just 1 min
                </Typography>
                <Typography sx={{ fontSize: "12px", fontFamily: "Inter" }}>
                  Proposed Ring Road
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="circle-5">
            <img src={logo} alt="" width={"100%"} />
            <Typography
              sx={{
                fontSize: "12px",
                marginTop: "-40%",
                textAlign: "center",
                lineHeight: "0.8rem",
                fontFamily: "Inter",
              }}
            >
              Location <br /> Benefits
            </Typography>
          </Box>
        </Box>

        <Grid container rowSpacing={5} mt={2}>
          <Grid item xs={12} md={6}>
            <Container
              sx={{
                width: "85%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                }}
              >
                Natural Attractions
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "200%",
                }}
              >
                📌 Nearby Tamhini Ghat, Mulshi Dam, and Lavasa for weekend
                getaways.
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container
              sx={{
                textAlign: { xs: "start", md: "end" },
                width: "85%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                }}
              >
                Growing Industrialization
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  lineHeight: "200%",
                  fontSize: "14px",
                }}
              >
                📌 Benefit from the exponential growth and industrialization in
                the region
              </Typography>
            </Container>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: "relative",
            height: "700px",
            display: { xs: "block", sm: "none" },
            overflow: "hidden",
          }}
          my={3}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "114%",
              textAlign: "center",
            }}
            mb={5}
          >
            Location Benefits
          </Typography>
          <Box
            className="mob-circle-1"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              className="inner-circle-1"
              sx={{ position: "absolute", top: "-2%" }}
            >
              <img src={car} alt="" width={"80%"} />
            </Box>
            <Box sx={{ textAlign: "center" }} mt={3}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                }}
              >
                Just 20 mins
              </Typography>
              <Typography sx={{ fontSize: "16px", fontFamily: "Inter" }}>
                Drive from Chandni Chowk
              </Typography>
            </Box>
          </Box>
          <Box
            className="mob-circle-2"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              className="inner-circle-1"
              sx={{ position: "absolute", top: "-2%" }}
            >
              <img src={car} alt="" width={"80%"} />
            </Box>
            <Box sx={{ textAlign: "center" }} mt={3}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                }}
              >
                Just 20 mins
              </Typography>
              <Typography sx={{ fontSize: "16px", fontFamily: "Inter" }}>
                Drive from Balewadi
              </Typography>
            </Box>
          </Box>
          <Box
            className="mob-circle-3"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              className="inner-circle-1"
              sx={{ position: "absolute", top: "-2%" }}
            >
              <img src={car} alt="" width={"80%"} />
            </Box>
            <Box sx={{ textAlign: "center" }} mt={3}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                }}
              >
                Just 10 mins
              </Typography>
              <Typography sx={{ fontSize: "16px", fontFamily: "Inter" }}>
                Drive from Hinjewadi Phase 3
              </Typography>
            </Box>
          </Box>
          <Box
            className="mob-circle-4"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              className="inner-circle-1"
              sx={{ position: "absolute", top: "-5%" }}
            >
              <img src={roadCircle} alt="" width={"80%"} />
            </Box>
            <Box sx={{ textAlign: "center" }} mt={3}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                }}
              >
                Just 1 min
              </Typography>
              <Typography sx={{ fontSize: "16px", fontFamily: "Inter" }}>
                Proposed Ring Road
              </Typography>
            </Box>

            <img
              src={logo}
              width={"130%"}
              alt=""
              style={{
                position: "absolute",
                left: "-12%",
                bottom: "-10%",
                zIndex: 500,
              }}
            />
          </Box>
        </Box>

        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              filter: isSubmitted ? "null" : "blur(7px)",
              position: { xs: "relative", sm: "static" },
              marginTop: { xs: "-10%", sm: "10%" },
            }}
          >
            <iframe
              title="location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.201881263074!2d73.65312557141742!3d18.519777030708344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bd005b2582e5%3A0x49ffd5f776da52e0!2sUrbanbrick%20Vista%20by%20Urbanbrick%20Developers!5e0!3m2!1sen!2sin!4v1722340888464!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{
                border: "0",
                pointerEvents: isSubmitted ? "auto" : "none",
              }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
          {isSubmitted ? null : (
            <button className="layout-btn" onClick={handleOpen}>
              Click to view
            </button>
          )}
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, top: { xs: "50%", sm: "50%", md: "15%" } }}>
            <form
              className="contact-form"
              style={{ width: "100%", padding: "30px 50px" }}
            >
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={handleClose} size="small">
                  x
                </Button>
              </Box>
              <Typography
                variant="h5"
                sx={{ fontFamily: "Kaisen Tokumin" }}
                mb={5}
              >
                BOOK A VISIT
              </Typography>
              <input
                type="text"
                placeholder="FULL NAME"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  validateName(e.target.value);
                }}
                onBlur={() => validateName(name)}
                style={{ marginBottom: "0px" }}
                required
              />
              {nameError && (
                <Typography
                  color="error"
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {nameError}
                </Typography>
              )}
              <input
                type="email"
                placeholder="EMAIL"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
                style={{ marginBottom: "0px", marginTop: "30px" }}
                required
              />
              {emailError && (
                <Typography
                  color="error"
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {emailError}
                </Typography>
              )}
              <input
                type="tel"
                placeholder="MOBILE NUMBER"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                  validateNumber(e.target.value);
                }}
                onBlur={() => validateNumber(number)}
                style={{ marginBottom: "0px", marginTop: "30px" }}
                required
              />
              {numberError && (
                <Typography
                  color="error"
                  mb={5}
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {numberError}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Button type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for success */}
      <Modal
        id='enquiry-success-modal-location-map'
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
        open={successOpen}
        onClose={handleSuccessClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={successOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center" mb={2}>
              ✅Thanks for contacting us! Our team will reach out to you
              shortly.
            </Typography>
            <Button
              onClick={handleSuccessClose}
              fullWidth
              sx={{
                background: "#00813d",
                color: "#fff",
                "&:hover": { background: "#00811d" },
              }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for error */}
      <Modal
        id='enquiry-error-modal'
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
        open={errorOpen}
        onClose={handleErrorClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={errorOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center">
              ❌ {errorMessage}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleErrorClose}
                fullWidth
                sx={{
                  background: "#00813d",
                  color: "#fff",
                  "&:hover": { background: "#00811d" },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Location;
