import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Leaf from "../UrbanBrick Website Asset File/Leaf image/leaf image-01.png";
import swimmingPool from "../UrbanBrick Website Asset File/Amenities Icon/swimming-pool.png";
import chair from "../UrbanBrick Website Asset File/Amenities Icon/chair.png";
import clubhouse from "../UrbanBrick Website Asset File/Amenities Icon/clubhouse.png";
import gym from "../UrbanBrick Website Asset File/Amenities Icon/battle-rope.png";
import bornfire from "../UrbanBrick Website Asset File/Amenities Icon/bonfire.png";
import barbecue from "../UrbanBrick Website Asset File/Amenities Icon/barbecue.png";
import playground from "../UrbanBrick Website Asset File/Amenities Icon/playground.png";
import party from "../UrbanBrick Website Asset File/Amenities Icon/dance.png";
import gazebo from "../UrbanBrick Website Asset File/Amenities Icon/gazebo.png";
import garden from "../UrbanBrick Website Asset File/Amenities Icon/park.png";
import yoga from "../UrbanBrick Website Asset File/Amenities Icon/meditation.png";
import indoorGames from "../UrbanBrick Website Asset File/Amenities Icon/luck.png";
import jogging from "../UrbanBrick Website Asset File/Amenities Icon/track-and-field.png";
import cafeteria from "../UrbanBrick Website Asset File/Amenities Icon/school-cafeteria.png";
import "./Amenities.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobileSmall: 320,
      mobileMedium: 375,
      mobileLarge: 425,
    },
  },
});

const Amenities = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className="amenities" id="amenities">
        <Container>
          <Box className="leaf-2">
            <img src={Leaf} alt="" width={"100%"} height={"100%"} />
          </Box>
          <Box
            className="heading"
            sx={{
              marginTop: {
                // mobileSmall: "50%",
                // mobileMedium: "50%",
                // mobileLarge: "0%",
                xs: "50%",
                sm: "20%",
                md: "10%",
              },
              position: 'relative'
            }}
          >
            <Typography variant="h4">
              <span className="ellipse"></span>
              Amenities
            </Typography>
            <Box
              className="amenities-text"
              sx={{ paddingTop: { xs: "5%", sm: "10%" } }}
            >
              <Typography
                variant="h5"
                sx={{
                  display: { xs: "none", sm: "block" },
                  textAlign: "center",
                }}
              >
                A plethora of thoughtful amenities
              </Typography>
              <Typography>
                Your personal oasis is surrounded by the finest amenities that
                offer an active lifestyle, a space to rejuvenate and create
                lifelong memories. Unwind amidst nature and live the life that
                you’ve always imagined.
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            className="amenity-grid"
            sx={{ marginTop: { xs: "50px", sm: "10%", md: "10%" } }}
            rowSpacing={{ xs: 2, sm: 5 }}
            columnSpacing={3}
          >
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={swimmingPool} alt="" width={"50%"} />
                <Typography>Swimming Pool</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={chair} alt="" width={"50%"} />
                <Typography>Poolside Dinning</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={clubhouse} alt="" width={"50%"} />
                <Typography>Clubhouse</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={gym} alt="" width={"50%"} />
                <Typography>Gym</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={bornfire} alt="" width={"50%"} />
                <Typography>Bornfire Area</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={barbecue} alt="" width={"50%"} />
                <Typography>Outdoor Barbecue Area</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={playground} alt="" width={"50%"} />
                <Typography>Kid's Play Area</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={party} alt="" width={"50%"} />
                <Typography>Party Lawn</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={gazebo} alt="" width={"50%"} />
                <Typography>Gazebos</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={garden} alt="" width={"50%"} />
                <Typography>Landscape Garden</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={yoga} alt="" width={"50%"} />
                <Typography>Yoga/Meditation Area</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={indoorGames} alt="" width={"50%"} />
                <Typography>Indoor Games</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={jogging} alt="" width={"50%"} />
                <Typography>Jogging Track</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box className="amenity">
                <img src={cafeteria} alt="" width={"50%"} />
                <Typography>Cafeteria</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Amenities;
