import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSubmitted } from "../redux/leadGenerationSlice";
import { useSearchParams } from "react-router-dom";

const style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const messageStyle = {
  ...style,
  width: 300,
  background: "#fff",
  padding: "20px",
  borderRadius: "10px",
};

export default function EnquiryModal() {
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const utmParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorClose = () => setErrorOpen(false);

  const validateName = (name) => {
    if (!name) {
      setNameError("Name is required.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required.");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validateNumber = (number) => {
    if (!number) {
      setNumberError("Number is required.");
      return false;
    } else if (!/^[6-9][0-9]{9}$/.test(number)) {
      setNumberError("Invalid Mobile Number.");
      return false;
    } else {
      setNumberError("");
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isNameValid = validateName(name);
    const isNumberValid = validateNumber(number);
    const isEmailValid = validateEmail(email);

    if (!isNameValid || !isNumberValid || !isEmailValid) return;

    const TELEGRAM_BOT_TOKEN = "7030443585:AAHLfBsrTuQAPhrgsTFSACEMs8gwdZ3oA7g";
    const TELEGRAM_CHAT_ID = -4139008254;
    const text = `New lead received for UrbanBrick Vista:\n\nName: ${name}\nEmail: ${email}\nNumber: ${number}\n\nutm: ${JSON.stringify(utmParams)}\n\nLead from: Enquiry Popup`;
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: TELEGRAM_CHAT_ID,
        text: text,
      });
      setOpen(false);
      setSuccessOpen(true);
      dispatch(setSubmitted());
    } catch (error) {
      setErrorMessage(error);
      setErrorOpen(true);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: { xs: "#fff", sm: "none" },
          height: "48px",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.06em",
            display: { xs: "block", sm: "none" },
          }}
        >
          <a href="tel:7385411057">+91 7385411057</a>
        </Typography>
        <Button
          onClick={handleOpen}
          sx={{
            rotate: { xs: 0, sm: "-90deg" },
            width: { xs: "100%" },
            background: "#f7931e",
            fontFamily: "Inter",
            height: { xs: "100%" },
            fontWeight: 500,
            fontSize: { xs: "14px", md: "14px" },
            lineHeight: "22px",
            color: "black",
            "&:hover": { background: "#ffb121" },
          }}
        >
          Enquire Now
        </Button>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, top: { xs: "50%", sm: "50%", md: "15%" } }}>
            <form
              className="contact-form"
              style={{ width: "100%", padding: "30px 50px" }}
              onSubmit={handleSubmit}
            >
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={handleClose} size="small">
                  x
                </Button>
              </Box>
              <Typography
                variant="h5"
                sx={{ fontFamily: "Kaisen Tokumin" }}
                mb={5}
              >
                BOOK A VISIT
              </Typography>
              <input
                type="text"
                placeholder="FULL NAME"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  validateName(e.target.value);
                }}
                onBlur={() => validateName(name)}
                style={{ marginBottom: "0px" }}
                required
              />
              {nameError && (
                <Typography
                  color="error"
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {nameError}
                </Typography>
              )}
              <input
                type="email"
                placeholder="EMAIL"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
                style={{ marginBottom: "0px", marginTop: "30px" }}
                required
              />
              {emailError && (
                <Typography
                  color="error"
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {emailError}
                </Typography>
              )}
              <input
                type="tel"
                placeholder="MOBILE NUMBER"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                  validateNumber(e.target.value);
                }}
                onBlur={() => validateNumber(number)}
                style={{ marginBottom: "0px", marginTop: "30px" }}
                required
              />
              {numberError && (
                <Typography
                  color="error"
                  mb={5}
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {numberError}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Button type="submit">Submit</Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for success */}
      <Modal
        id='enquiry-success-modal-btn'
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
        open={successOpen}
        onClose={handleSuccessClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={successOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center" mb={2}>
              ✅Thanks for contacting us! Our team will reach out to you
              shortly.
            </Typography>
            <Button
              onClick={handleSuccessClose}
              fullWidth
              sx={{
                background: "#00813d",
                color: "#fff",
                "&:hover": { background: "#00811d" },
              }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for error */}
      <Modal
        id='enquiry-error-modal'
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
        open={errorOpen}
        onClose={handleErrorClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={errorOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center">
              ❌ {errorMessage}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleErrorClose}
                fullWidth
                sx={{
                  background: "#00813d",
                  color: "#fff",
                  "&:hover": { background: "#00811d" },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
